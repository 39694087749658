@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root {
  height: 100vh;
}

@media print {
  @page {
    margin-top: 0;
  }

  body {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-print-color-adjust: exact !important;
  }
}
